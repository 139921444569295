import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { OAuthStorageService } from '@qaroni-app/application/auth';
import { Branch } from '@qaroni-core/entities/merchant';
import { AllAppService, CommonsHttpService } from '@qaroni-core/services';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Merchant } from '../types/merchant';
import { MerchantHttpService } from './merchant-http.service';

@Injectable({
  providedIn: 'root',
})
export class MerchantService {
  protected readonly merchantsSubject = new Subject<Merchant[]>();
  protected readonly merchantSubject = new Subject<Merchant>();
  protected readonly branchSubject = new Subject<Branch>();

  constructor(
    private allApp: AllAppService,
    private merchantHttp: MerchantHttpService,
    private commonsHttp: CommonsHttpService,
    private oAuthStorage: OAuthStorageService
  ) {}

  private enableLoading(): void {
    this.allApp.progressBar.show();
  }

  private disableLoading(): void {
    this.allApp.progressBar.hide();
  }

  public hasLatLng(merchant: Merchant): boolean {
    if (merchant?.latitude && merchant?.longitude) {
      return true;
    }
    return false;
  }

  public getLatLng(merchant: Merchant): google.maps.LatLngLiteral {
    if (this.hasLatLng(merchant)) {
      const latLng: google.maps.LatLngLiteral = {
        lat: merchant?.latitude,
        lng: merchant?.longitude,
      };
      return latLng;
    }
    return null;
  }

  // ==========================================================================================
  // Get Merchants
  // ==========================================================================================

  public getMerchants$(): Observable<Merchant[]> {
    return this.merchantsSubject.asObservable();
  }

  public getMerchants(params?: Params): void {
    this.enableLoading();
    this.merchantHttp
      .getMerchants$(params)
      .pipe(finalize(() => this.disableLoading()))
      .subscribe({
        next: this.nextGetMerchants,
        error: this.errorGetMerchants,
      });
  }

  private nextGetMerchants = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchants: Merchant[] = data.body.result;
      this.merchantsSubject.next(merchants);
    } else {
      this.merchantsSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetMerchants = (error: HttpErrorResponse): void => {
    this.merchantsSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Get Merchant
  // ==========================================================================================

  public getMerchant$(): Observable<Merchant> {
    return this.merchantSubject.asObservable();
  }

  public getMerchant(): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .getMerchant$(this.oAuthStorage.getMerchantID)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextGetMerchant,
          error: this.errorGetMerchant,
        });
    }
  }

  private nextGetMerchant = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchant: Merchant = data.body.result[0];
      this.merchantSubject.next(merchant);
    } else {
      this.merchantSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetMerchant = (error: HttpErrorResponse): void => {
    this.merchantSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Update Merchant
  // ==========================================================================================

  public updateMerchant(merchant: Merchant): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .updateMerchant$(this.oAuthStorage.getMerchantID, merchant)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextUpdateMerchant,
          error: this.errorUpdateMerchant,
        });
    }
  }

  private nextUpdateMerchant = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchant: Merchant = data.body.result[0];
      this.merchantSubject.next(merchant);
    } else {
      this.merchantSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorUpdateMerchant = (error: HttpErrorResponse): void => {
    this.merchantSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Add Image
  // ==========================================================================================

  public addImage(dataMultipart: FormData): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .addImage$(this.oAuthStorage.getMerchantID, dataMultipart)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({ next: this.nextAddImage, error: this.errorAddImage });
    }
  }

  private nextAddImage = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchant: Merchant = data.body.result[0];
      this.merchantSubject.next(merchant);
    } else {
      this.merchantSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorAddImage = (error: HttpErrorResponse): void => {
    this.merchantSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Delete Image
  // ==========================================================================================

  public deleteImage(): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .deleteImage$(this.oAuthStorage.getMerchantID)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextDeleteImage,
          error: this.errorDeleteImage,
        });
    }
  }

  private nextDeleteImage = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchant: Merchant = data.body.result[0];
      this.merchantSubject.next(merchant);
    } else {
      this.merchantSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorDeleteImage = (error: HttpErrorResponse): void => {
    this.merchantSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Add Branch
  // ==========================================================================================

  public createBranch(branch: Branch): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .createBranch$(this.oAuthStorage.getMerchantID, branch)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextCreateBranch,
          error: this.errorCreateBranch,
        });
    }
  }

  private nextCreateBranch = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus201(data)) {
      const merchant: Merchant = data.body.result[0];
      this.merchantSubject.next(merchant);
    } else {
      this.merchantSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorCreateBranch = (error: HttpErrorResponse): void => {
    this.merchantSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Get Branch
  // ==========================================================================================

  public getBranch$(): Observable<Branch> {
    return this.branchSubject.asObservable();
  }

  public getBranch(branchID: number | string): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .getBranch$(this.oAuthStorage.getMerchantID, branchID)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextGetBranch,
          error: this.errorGetBranch,
        });
    }
  }

  private nextGetBranch = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const branch: Branch = data.body.result[0];
      this.branchSubject.next(branch);
    } else {
      this.branchSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetBranch = (error: HttpErrorResponse): void => {
    this.branchSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Update Branch
  // ==========================================================================================

  public updateBranch(branchID: number | string, branch: Branch): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .updateBranch$(this.oAuthStorage.getMerchantID, branchID, branch)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextUpdateBranch,
          error: this.errorUpdateBranch,
        });
    }
  }

  private nextUpdateBranch = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchant: Merchant = data.body.result[0];
      this.merchantSubject.next(merchant);
    } else {
      this.merchantSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorUpdateBranch = (error: HttpErrorResponse): void => {
    this.merchantSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Delete Branch
  // ==========================================================================================

  public deleteBranch(branchID: number | string): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .deleteBranch$(this.oAuthStorage.getMerchantID, branchID)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextDeleteBranch,
          error: this.errorDeleteBranch,
        });
    }
  }

  private nextDeleteBranch = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchant: Merchant = data.body.result[0];
      this.merchantSubject.next(merchant);
    } else {
      this.merchantSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorDeleteBranch = (error: HttpErrorResponse): void => {
    this.merchantSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Add Branch Image
  // ==========================================================================================

  public addBranchImage(
    branchID: number | string,
    dataMultipart: FormData
  ): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .addBranchImage$(
          this.oAuthStorage.getMerchantID,
          branchID,
          dataMultipart
        )
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextAddBranchImage,
          error: this.errorAddBranchImage,
        });
    }
  }

  private nextAddBranchImage = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const branch: Branch = data.body.result[0];
      this.branchSubject.next(branch);
    } else {
      this.branchSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorAddBranchImage = (error: HttpErrorResponse): void => {
    this.branchSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Delete Branch Image
  // ==========================================================================================

  public deleteBranchImage(branchID: number | string): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .deleteBranchImage$(this.oAuthStorage.getMerchantID, branchID)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextDeleteBranchImage,
          error: this.errorDeleteBranchImage,
        });
    }
  }

  private nextDeleteBranchImage = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const branch: Branch = data.body.result[0];
      this.branchSubject.next(branch);
    } else {
      this.branchSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorDeleteBranchImage = (error: HttpErrorResponse): void => {
    this.branchSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }
}
