export const AppUrlsConfig = {
  postSignUp: `https://presco.s3.eu-central-1.amazonaws.com/logo/logo_b_presco_blue.png`,
  postSignUpAlt: `Register successful`,

  ownerAppOnPlayStore: `https://play.google.com/store/apps/details?id=com.qaroni.presco_android`,

  googlePlayStoreIcon: `https://presco.s3.eu-central-1.amazonaws.com/logo/google-play.png`,
  googlePlayStoreIconAlt: `Google Play Store`,

  ownerAppOnAppleStore: `https://apps.apple.com/es/app/bonos-coruña/id1525442051`,

  appleStoreIcon: `https://presco.s3.eu-central-1.amazonaws.com/logo/app-store.png`,
  appleStoreIconAlt: `Apple App Store`,

  resourcesShop: {
    vinyl:
      'https://presco.s3.eu-central-1.amazonaws.com/images/vinyls/Vinilo_Bono_COMERCIO.pdf',
    poster:
      'https://presco.s3.eu-central-1.amazonaws.com/images/posters/A3_Presco_Comercio.pdf',
  },
  resourcesCulture: {
    vinyl:
      'https://presco.s3.eu-central-1.amazonaws.com/images/vinyls/Vinilo_Bono_CULTURA.pdf',
    poster:
      'https://presco.s3.eu-central-1.amazonaws.com/images/posters/A3_Presco_Cultura.pdf',
  },
  resourcesEstetica: {
    vinyl:
      'https://presco.s3.eu-central-1.amazonaws.com/images/vinyls/Vinilo_Bono_ESTETICA.pdf',
    poster:
      'https://presco.s3.eu-central-1.amazonaws.com/images/posters/A3_Presco_Estetica.pdf',
  },
  resourcesHostaleria: {
    vinyl:
      'https://presco.s3.eu-central-1.amazonaws.com/images/vinyls/Vinilo_Bono_HOSTALARIA.pdf',
    poster:
      'https://presco.s3.eu-central-1.amazonaws.com/images/posters/A3_Presco_Hostalaria.pdf',
  },
  resourcesTaxi: {
    vinyl:
      'https://presco.s3.eu-central-1.amazonaws.com/images/vinyls/Vinilo_Bono_TAXI.pdf',
    poster: null,
  },
};
