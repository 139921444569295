<div @enterFadeIn class="alert alert-primary" role="alert">
  <h4 class="alert-heading font-weight-bold">
    {{ "Gestiona el perfil de tu comercio" | translate }}
  </h4>
  {{ "Crea y administra sucursales" | translate }}.
  {{
    "Administra el nombre comercial, imagen, dirección, palabras clave, página web"
      | translate
  }}.
  <a
    [routerLink]="['/dashboard/account']"
    class="alert-link text-decoration-none"
    >{{ "Accede aquí" | translate }}</a
  >.
</div>
