import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarConfig } from '@qaroni-core/config';

export const OAuthSnackbars = {
  successRegister: {
    message: `Se registró correctamente tu cuenta, hemos enviado un contrato para la firma al correo electrónico`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failureRegisterEmailExists: {
    message: `El correo electrónico ya se encuentra registrado`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureRegisterPhoneExists: {
    message: `El número de teléfono móvil ya se encuentra registrado`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureRegisterNIFExists: {
    message: `El NIF/CIF (Número de Identificación Fiscal) ya se encuentra registrado`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureRegisterExists: {
    message: `Ya existe un usuario registrado con estos datos`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  successValidate: {
    message: `Se validó correctamente el código de confirmación`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failureValidate: {
    message: `Código de confirmación incorrecto, por favor, revisa el SMS enviado al número de teléfono móvil e introduce el código nuevamente`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureLogin: {
    message: `Credenciales inválidas, no se puede iniciar sesión`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureLoginCancelled: {
    message: `Cuenta cancelada. No se puede iniciar sesión`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureLoginInactive: {
    message: `Tu cuenta se encuentra inactiva`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureLoginTooManyFailedAttempts: {
    message: `Demasiados intentos fallidos. Intenta recuperar tu cuenta`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  successForgot: {
    message: `Se ha enviado por SMS el código de confirmación`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failureForgot: {
    message: `Credenciales inválidas, no se puede enviar el código de confirmación`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureForgotRole: {
    message: `Usuario incorrecto, no estás en la plataforma correspondiente a tu tipo de usuario`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureForgotInactive: {
    message: `Cuenta inactiva. No se puede hacer la recuperación de la contraseña`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureForgotCancelled: {
    message: `Cuenta cancelada. No se puede hacer la recuperación de la contraseña`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  successResetPassword: {
    message: `Se actualizó la contraseña`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failureChangePasswordOldPassword: {
    message: `La contraseña actual es errónea`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureChangePasswordSame: {
    message: `Las contraseñas actual y nueva deben ser diferentes`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureChangePassword: {
    message: `Ocurrió un error actualizando la contraseña`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureChangePhone: {
    message: `El número de teléfono móvil ingresado ya existe.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
};
