<div @enterFadeIn class="alert alert-danger" role="alert">
  <h4 class="alert-heading font-weight-bold">
    {{ "Atención COMERCIOS" | translate }}
  </h4>
  <p>
    {{
      "Si puedes iniciar sesión, es una prueba inequívoca que la cuenta está correcta, activa y"
        | translate
    }}
    <strong>{{
      "no debes realizar ningún otro trámite de registro adicional" | translate
    }}</strong
    >.
  </p>
  <p>
    {{
      "Aclaratoria debido a la confusión generada por el correo electrónico enviado el 27/04/2021"
        | translate
    }}.
  </p>
</div>
