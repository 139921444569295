<div class="row">
  <div class="col-12 mb-3">
    <mat-card @enterFadeIn class="bg-grey">
      <mat-card-content class="text-right">
        <div class="row" *ngIf="resources">
          <div class="col-auto mr-0 mb-1" *ngIf="resources.poster">
            <a [href]="resources.poster" target="_blank" download>
              <button mat-raised-button color="primary" type="button">
                <mat-icon>file_download</mat-icon>

                {{ "Descargar cartel" | translate }}
              </button>
            </a>
          </div>
          <div class="col-auto ml-0" *ngIf="resources.vinyl">
            <a [href]="resources.vinyl" target="_blank" download>
              <button mat-raised-button color="primary" type="button">
                <mat-icon>file_download</mat-icon>

                {{ "Descargar vinilo" | translate }}
              </button>
            </a>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
