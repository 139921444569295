import { Component, OnInit } from '@angular/core';
import { qaroniToggleFadeTrigger } from '@qaroni-shared/animations';

@Component({
  selector: 'qaroni-help-section',
  templateUrl: './help-section.component.html',
  styleUrls: ['./help-section.component.scss'],
  animations: [qaroniToggleFadeTrigger],
})
export class HelpSectionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
