import { Component, OnInit } from '@angular/core';
import { AppUrlsConfig } from '@qaroni-core/config';
import { qaroniEnterFadeInTrigger } from '@qaroni-shared/animations';

@Component({
  selector: 'qaroni-alert-support',
  templateUrl: './alert-support.component.html',
  styleUrls: ['./alert-support.component.scss'],
  animations: [qaroniEnterFadeInTrigger],
})
export class AlertSupportComponent implements OnInit {
  public AppUrlsConfig = AppUrlsConfig;
  constructor() {}

  ngOnInit(): void {}
}
