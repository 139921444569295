import { Component, OnInit } from '@angular/core';
import { OAuthStorageService } from '@qaroni-app/application/auth';
import { AppUrlsConfig } from '@qaroni-core/config';
import { MerchantTypeEnum, Resources } from '@qaroni-core/entities';
import { qaroniEnterFadeInTrigger } from '@qaroni-shared/animations/enter-fade-in-trigger';

@Component({
  selector: 'qaroni-poster-vinysl-downloads',
  templateUrl: './poster-vinysl-downloads.component.html',
  styleUrls: ['./poster-vinysl-downloads.component.scss'],
  animations: [qaroniEnterFadeInTrigger],
})
export class PosterVinyslDownloadsComponent implements OnInit {
  AppUrlsConfig = AppUrlsConfig;
  resources: Resources;
  merchantType = '';

  constructor(public oAuthStorage: OAuthStorageService) {
    this.merchantType = oAuthStorage.getMerchantType();
  }

  ngOnInit(): void {
    this.resources = this.getResourcesByMerchantType();
  }

  getResourcesByMerchantType(): Resources {
    if (MerchantTypeEnum.SHOP === this.merchantType) {
      return {
        vinyl: AppUrlsConfig.resourcesShop.vinyl,
        poster: AppUrlsConfig.resourcesShop.poster,
      };
    } else if (MerchantTypeEnum.CULTURE === this.merchantType) {
      return {
        vinyl: AppUrlsConfig.resourcesCulture.vinyl,
        poster: AppUrlsConfig.resourcesCulture.poster,
      };
    } else if (MerchantTypeEnum.SALON === this.merchantType) {
      return {
        vinyl: AppUrlsConfig.resourcesEstetica.vinyl,
        poster: AppUrlsConfig.resourcesEstetica.poster,
      };
    } else if (MerchantTypeEnum.HOSTELRY === this.merchantType) {
      return {
        vinyl: AppUrlsConfig.resourcesHostaleria.vinyl,
        poster: AppUrlsConfig.resourcesHostaleria.poster,
      };
    } else if (MerchantTypeEnum.TAXI === this.merchantType) {
      return {
        vinyl: AppUrlsConfig.resourcesTaxi.vinyl,
        poster: AppUrlsConfig.resourcesTaxi.poster,
      };
    }
  }
}
